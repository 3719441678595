// -----------------------------------------------------------------------------
// Not proud of this!
// -----------------------------------------------------------------------------

/// Pages
.profile {
    max-width: 320px;
    margin: auto;
    padding-bottom: 0.5em;
}

a.next, a.prev {
    padding: 0.5em;
    display: inline-block;
    svg {
        display: inline-block;
        vertical-align: middle;
    }
}