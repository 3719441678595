
// 1. Configuration and helpers
  // Bourbon and Neat are already imported


// 2. Vendors
@import 'vendor/flexslider';
@import 'vendor-extensions/flexslider';
@import 'vendor-extensions/lightbox';


// 4. Layout
@import	'layout/header';
@import 'layout/footer';
@import 'layout/navigation';
@import 'layout/gallery';
@import 'layout/forms';

// 5. Components
@import	'better-bourbon-buttons/bbb';
@import 'components/page';
@import 'components/helpers';
@import 'components/post';
@import 'components/carousel';
@import 'components/cloudcannon';
@import 'components/cover';
@import 'components/product';
@import 'components/svg';

// 6. Page-specific
@import	'pages/home';

// 7. Themes
@import 'theme/vanilla';

// 8. Shame
@import 'shame';