figure {
    margin: 0 0 1.5em;
  }
  
  figcaption {
    font-style: italic;
    text-align: center;
  }
  
  img,
  picture {
    margin: 0;
    max-width: 100%;
  }
  .video--responsive {
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;
    iframe {
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      position: absolute;
    }
  }
  