

.product--description {
  @include grid-media ($small) {
    width: 400px;
  }
  padding: 25px;
  margin: 25px;
  background-color: lighten($action-color,60%);
}
.paypal--add-item {
  background-color: $action-color;
}
.paypal--add-item:hover {
  box-shadow: 0px 3px 5px #888888;
}

.paypal--add-item:active {
  box-shadow: 0 0 0 0;
}
.product--details {
  @include grid-media ($small) {
    /*display: flex;
    flex-direction: row;*/
    justify-content: center;
    align-items: center;
  }
}

.product {
  @include grid-media ($small) {
    /*display: flex;
    flex-direction: column;*/
    align-items: center;
    justify-content: center;
  }
  padding: 0.2em;
}