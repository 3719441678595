
.posts--list {
    list-style-type: none;
    margin: 0;
    padding: 0;
  
  }
  
  .post--inlist {
      border-bottom: 1px solid $action-color;
      //padding: modular-scale(-4);
      padding: 1em 0;
      @include grid-media($large) {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
      }
  }
  .post--image {
      @include grid-media($large) {
        flex: 0 1 30%;
      }
  }
  .post--content { // position right with neat
      @include grid-media($large) {
        padding-top: 0;
        flex: 0 1 65%;
      }
  }
  // grid stuff
  @supports (display: grid) {
  
    @include grid-media($large) {
      .post--inlist {
        display: grid;
        grid-template-columns: 1fr 2fr;
        grid-gap: 1rem;
      }
      .post--image,
      .post--content {
        width: auto;
      }
    }
  }
  .post--date {
    font-style: italic;
  
  }
  .post--rss {
  
  }
  .pagination {
      padding-top: 2em;
      text-align: center;
      color: $medium-gray;
      .page--number {
          padding: 0 1em;
      }
  }
  .link--share {
   text-decoration: none;
  }
  
  ///   Full Posts
  
  .post--header-container {
      .scrim {
          padding: 2em 1em 1em 1em;
      }
  }
  .post--meta {
      padding-bottom: 1em;
  }
  .post--readmore {
    color: $action-color;
    &:hover, &:active {
      color: $action-hover-color;
    }
  }
  .post--header {
    padding-bottom: 1.5em;
  }
  
  .post--title {
    margin-bottom: 0.2em;
    text-decoration: none;
    //color: $action-color;
  }
  
  .post--related {
    margin-bottom: 1em;
    padding-bottom: 2em;
    border-bottom: 1px solid $action-color;
  }
  
  .post--navigation {
      font-size: 0.9em;
      display: block;
      width: auto;
      .prev--post {
          display: block;
          width: 50%;
          float: left;
          margin: 1em 0;
      }
      .next--post {
          display: block;
          width: 50%;
          float: left;
          margin: 1em 0;
          text-align: right;
      }
    }
  .post--share {
      font-weight: bold;
      margin: 0 0 1em 0;
      a {
          width: 1em;
          padding: 0 0.25em;
          text-decoration: none;
      }
  }
  .post--nav {
      text-align: center;
      border-top: 1px solid $light-gray;
      padding: 1em 0;
  }