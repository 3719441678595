// -----------------------------------------------------------------------------
// This file contains styles that are specific to cover images -
// full width background images
// -----------------------------------------------------------------------------

///  Cover
.site-header-container, .post-header-container {
    width: 100%;

}
///  Cover

.scrim {
    padding: 20em 0 0 0;
}
.has--cover {
  background-position: 50% 50%;
  background-size: cover;
  color: $base-font-color;
  .title {
    font-size: modular-scale(4);
  }
}
.cover--title {
   //font-size: modular-scale(4);
   //font-family: $site-title-font-family;
   color: $black;
   font-weight: 700;
}