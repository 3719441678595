ul,
ol {

  &%default-ul,
  &%default-ol {
  	list-style-type: none;
	  margin: 0;
	  padding: 0;
  }
  
}

ul {
	list-style-type: disc;
  margin-bottom: $small-spacing;
  padding-left: $base-spacing;
}

ol {
	list-style-type: decimal;
  margin-bottom: $small-spacing;
  padding-left: $base-spacing;
}

dl {
  margin-bottom: $small-spacing;

  dt {
    font-weight: bold;
    margin-top: $small-spacing;
  }

  dd {
    margin: 0;
  }
}
ul.default-ul,
ol.default-ol {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.list--horizontal {
  list-style-type: none;
  margin: 0;
  //display: inline;

  li {
    display: inline;
    padding-right: 1em;
  }
}
.list--bulleted {
  list-style-type: none;
  list-style-position: inside;
  margin-left: 15px;
  margin-bottom: 10px;

  li::before {
    content: "•";
    color: $action-color;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
  }
}

