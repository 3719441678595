

.page--content {
    max-width: 1200px;
    padding: 0;
    padding-bottom: 120px;
    margin: 0 auto;
    flex: 1 0 auto;
    width: 100%;
  }
  
  .page {
     padding: 0 1em 1em;
     max-width: 48em;
     margin: 0 auto;
    @include grid-media ($small) {
      padding: 1em;
    }
  }
  .page--header {
    padding-bottom: 1.5em;
  }
  .page--title {
    margin-bottom: 0.2em;
    text-align: center;
  }
  